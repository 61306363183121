import { DiscordIconTransparent } from "../Icons/DiscordIconTransparent";
import { EmailIconTransparent } from "../Icons/EmailIconTransparent";
import { TwitterIcon } from "../Icons/TwitterIcon";
import { Logo } from "../Icons/Logo";
import { NewDocument } from "../NewDocument";
import { GithubStar } from "../UI/GithubStar";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "../UI/Popover";

export function HomeHeader({ fixed }: { fixed?: boolean }) {
  return (
    <header
      className={`${
        fixed ? "fixed" : ""
      } z-20 flex items-center justify-left w-screen h-[82px] px-4 bg-indigo-700`}
    >
      <div className="flex w-8 sm:w-8 mr-3 align-left">
        <Logo />
      </div>
      <div className="flex w-36 sm:w-44 mr-3 align-left text-white text-bold">
        JSON Editor
      </div>
    </header>
  );
}
